//
// Color system
//



$primary: #18A689;


$secondary: #888888;


$info: #17c1e8;


$success: #82d616;


$danger: #ea0606;


$warning: #fbcf33;


$light: #e9ecef;


$dark: #344767;


$white: #fff;


$black: #111;


//
// Bootstrap components
//
$breadcrumb-divider: quote(">");

//
// Fonts
//


$web-font-path: "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap";
@import url($web-font-path);

$font-family-base: "Open Sans", sans-serif;


$headings-font-family: "Open Sans", sans-serif;



//
// Jekyll-Lydde-Theme
//
@import "main.scss";

//
// Append your custom styles bellox
//