/*
 * LYDDE Group - Jekyll & Bootstrap Theme
 * Copyright 2011-2023 YAKA Game Studio
 * Copyright 2011-2023 The Bootstrap Authors
 * Copyright 2011-2023 Twitter, Inc.
 *
 * Theme variables
 *
 */


