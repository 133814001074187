/*
 * LYDDE Group - Jekyll & Bootstrap Theme
 * Copyright 2011-2023 YAKA Game Studio
 * Copyright 2011-2023 The Bootstrap Authors
 * Copyright 2011-2023 Twitter, Inc.
 *
 */

/*
 *
 *  Default Website structure
 *  header - Manage the Navigation
 *  hero - Manage the hero section with carousel and call to action
 *  breadcrumb - Manage the breadcrumb navigation
 *  footer - Manage the footer links
 *
*/
//
// Header
//
#page-header 
{
    @extend .text-muted;
}
#page-header > div 
{
    @extend .container;
}
#page-header-top
{
    @extend .flex-wrap;
    @extend .d-none;
    @extend .d-xl-flex;
}
#page-header-top-left
{
    @extend .nav;
    @extend .me-auto;
}
#page-header-top-right
{
    @extend .nav;
}
#page-header-top-right > ul
{
    @extend .nav;
    @extend .justify-content-end;
}
#page-header-top-right > ul > li
{
    @extend .nav-item;
}
#page-header-top-right > ul > li > a
{
    @extend .nav-link;
}
#page-header-main 
{
    @extend .navbar;
    @extend .navbar-expand-lg;
}
#page-header-main > div 
{
    @extend .container-fluid;
}
#page-header-main > #logo > a
{
    @extend .navbar-brand;
}
//
// NavBar
//
#navbarContent
{
    @extend .collapse;
    @extend .navbar-collapse;
    @extend .p-2;
    @extend .pt-5;    
    @extend .p-lg-0;
}
#navbarContent > div > ul
{
    @extend .navbar-nav;
    @extend .nav-pills;
}
#navbarContent > div > ul > li
{
    @extend .nav-item;
}
#navbarContent > div > ul a
{
    @extend .nav-link;
}


#page-header-bottom
{
    @extend .d-lg-none;
}
//
// Hero Display
//
#hero 
{
    @extend .position-relative;
    @extend .bg-light;
    overflow: hidden;
    z-index: 0;
    height: 640px;
}
//
// Hero Visual Content
//
#hero .background 
{
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity:0.8;
}
//
// Hero Text Content
//
#hero-content
{
    @extend .container;
    @extend .position-absolute;
    @extend .top-50;
    @extend .start-50;
    @extend .translate-middle;

    z-index: 2;
    background:rgba(255, 255, 255, 0.8);
    padding:5rem;
    border:1px solid rgba(255, 255, 255, 0.1);
    border-radius:5px;
}
#hero-content > div 
{
    @extend .row;
    @extend .align-items-center;
}
#hero-content > div  > div 
{
    @extend .col;
    @extend .align-items-center;
}
#hero-information h1
{
    @extend .display-4;
}
#hero-information p
{
    @extend .lead;
}

#hero-action
{
    @extend .text-center;
}
#hero-action a
{
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-lg;
}
#hero-action p
{
    @extend .lead;
}
//
// Breadcrumb
//
#breadcrumb > ol
{
    @extend .breadcrumb;
}
#breadcrumb > ol > li
{
    @extend .breadcrumb-item;
}
#breadcrumb a
{
    @extend .link-body-emphasis;
    @extend .fw-semibold;
    @extend .text-decoration-none;
}
//
// Social
// 
.social
{
    @extend .nav;
}
.social > li
{
    @extend .nav-item;
}
.social > li > a
{
    @extend .nav-link;
    @extend .link-dark;
    @extend .link-opacity-50;
    @extend .link-opacity-100-hover;
}
//
// Main
//
body > #page-content > main
{
    @extend .container;
    @extend .pt-3;
}
//
// Footer
//
#page-footer
{
    @extend .border-top;
    @extend .text-muted;
}
#page-footer > div
{
    @extend .container;
}
#page-footer-top
{
    @extend .row;
}
#page-footer-main
{
    @extend .row;
    @extend .pt-3;
    @extend .border-bottom;
}
#page-footer-main > div
{
    @extend .col;
}
#page-footer-main .footer-menu
{
    @extend .col-lg-3;
    @extend .col-sm-6;
}
#page-footer-main #social
{
    @extend .col-lg-6;    
    @extend .col-sm-12;
}
#page-footer-main > div > span, #page-footer-main > div > h2
{
    @extend .h5;
}
#page-footer-main .footer-menu ul
{
    @extend .nav;
    @extend .flex-column;
    @extend .pb-3;
}
#page-footer-main .footer-menu li
{
    @extend .nav-item;
    @extend .mb-2;
}
#page-footer-main .footer-menu a
{
    @extend .nav-link;
    @extend .p-0;
    @extend .text-muted;
}
#page-footer-bottom > div
{
    @extend .row;
    @extend .py-3;
}
#page-footer-bottom > div > div
{
    @extend .col-sm-6;
}
#page-footer-bottom #disclaimer
{
    @extend .text-center;
    @extend .text-lg-end;
}
/*
    Post
    - header
    - main
    - footer
*/
#post
{

}
